export default {
	deliveryTimeline: {
		linkLabel: 'purchases',
		text: 'Use Motorway Move, and get detailed delivery updates so you know when to expect your purchases. Just click ‘delivery details’ in your',
		title: 'Track your deliveries',
	},
	kycCTA: {
		linkLabel: 'complete and submit your company information',
		text: 'To have access to all sections of the platform, ',
		title: 'Submit company information',
	},
	paymentsKYC: {
		ctaLabel: 'Complete your company information',
		text: '{cta} now so that you’re ready to make payments via Motorway Pay. From 1st April 2025, if you continue to pay the seller directly there will be a £10 Admin fee added per vehicle.',
		title: 'Submit company information',
	},
};
