import { useLocalStorage } from 'usehooks-ts';

type BannerData = { id: string; interval: number };

const isValidInterval = (interval: unknown): boolean => {
	return !Number.isNaN(Number(interval));
};

export const useIsBannerVisible = (bannerId: string): boolean => {
	const [bannerData] = useLocalStorage<BannerData>(bannerId, {} as BannerData);
	const visible = !bannerData?.interval || !isValidInterval(bannerData.interval) || Date.now() >= bannerData.interval;

	return visible;
};

export const useIsBannerDismissed = (bannerId: string): boolean => {
	return useIsBannerVisible(bannerId) === false;
};
