import React from 'react';
import cx from 'classnames';
import { MDText } from 'i18n-react';

import { InfoBanner } from '@motorway/motorway-component-library';
import { Badge } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import type { BannerProps } from './Banner.types';

import styles from './Banner.module.scss';

// 'id' will need changing for every new campaign - otherwise it may be hidden from the last time it was used.
export const Banner: React.FC<BannerProps> = ({
	badge,
	id,
	isDismissible = true,
	linkProps,
	shouldPreserveNewLines,
	texts,
}) => {
	const LocalT = new MDText({ ...texts, badge: { text: badge?.text } });
	const { linkLabel } = texts || {};

	return (
		<div className={styles.component} data-banner-id={id} data-testid={id}>
			<InfoBanner
				className={cx(styles.bannerWrapper, !isDismissible && 'isNotDismissable')}
				icon={null}
				id={id}
				interval={Number.MAX_VALUE}
				title={
					<>
						<div className={styles.bannerTitleContainer}>
							{badge?.text && (
								<div className={styles.bannerTag}>
									<Badge data-thc-badge data-testid="badge" {...badge} text={LocalT.translate(badge.text as string)} />
								</div>
							)}
							<span>{LocalT.translate(texts.title, texts.titleOptions)}</span>
						</div>
						<span className={cx(styles.info, { [styles.preserveNewLines]: Boolean(shouldPreserveNewLines) })}>
							{LocalT.translate(texts.text, texts.textOptions)}
							{linkLabel ? <Hyperlink {...linkProps} as="a" label={LocalT.translate(texts.linkLabel!)} /> : null}
						</span>
					</>
				}
				titleClassName={styles.title}
			/>
		</div>
	);
};
