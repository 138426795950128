import { MDText } from 'i18n-react';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import type { BannerProps } from 'Components/Banner/Banner.types';
import { DEALER_STATUS } from 'Utilities/consts';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import usePaymentsMVPEnabled from 'Utilities/hooks/usePaymentsMVPEnabled';
import useUser from 'Utilities/hooks/useUser';
import ROUTES from 'Utilities/routes';

import LocalTexts from '../SitewideBanner.texts';
import type { BannerConfig } from '../SitewideBanner.types';

import { useIsBannerDismissed } from './useIsBannerDismissed';

const localT = new MDText(LocalTexts.paymentsKYC);

type DealerStatuses = (typeof DEALER_STATUS)[keyof typeof DEALER_STATUS];

const userStatusesDisallowedByBanner = new Set<DealerStatuses>([DEALER_STATUS.APPROVED_PREVIEW]);

const id: BannerProps['id'] = 'paymentsKYC';
const badge: BannerProps['badge'] = { text: 'Reminder' };
const isDismissible: BannerProps['isDismissible'] = true;
const shouldPreserveNewLines: BannerProps['shouldPreserveNewLines'] = true;
const linkProps: BannerConfig['linkProps'] = {
	href: ROUTES.ACCOUNT_SETTINGS_COMPANY_INFORMATION.href,
	target: '_blank',
};
const texts: BannerProps['texts'] = {
	text: LocalTexts.paymentsKYC.text,
	textOptions: {
		cta: <Hyperlink {...linkProps} as="a" label={localT.translate('ctaLabel')} />,
	},
	title: LocalTexts.paymentsKYC.title,
};

export const usePaymentsKYCBannerConfig = (): BannerConfig => {
	const isPaymentsMVPEnabled = usePaymentsMVPEnabled();
	const isPaymentsKYCBannerDisplayed = useFeatureToggle(FEATURES.paymentsKYC);
	const isDismissed = useIsBannerDismissed(id);
	const user = useUser();

	const { dealerStatus, isLoggedOut } = user || {};

	const userStatus = dealerStatus?.slug as DealerStatuses;
	const hasValidUserStatus = !userStatusesDisallowedByBanner.has(userStatus);
	const hasValidUserData = hasValidUserStatus && !isLoggedOut;

	const isEnabled = isPaymentsKYCBannerDisplayed && !isDismissed && !isPaymentsMVPEnabled && hasValidUserData;

	return { badge, id, isDismissible, isEnabled, priorityIndex: 99999, shouldPreserveNewLines, texts };
};
